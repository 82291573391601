<template>
  <div class="register">
    <link rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
    crossorigin="anonymous">
    <h2>Verify User Account</h2>
    <br>
    <div class="passwordFailure">
      <h4 v-if="isAuthError == true">{{authError}}</h4>
    </div>
    <div class="entryform">
      <div class="entrybox">
        <input class="inputbar" v-model="email" placeholder="Email">
      </div>
      <div class="entrybox">
        <input class="inputbar" v-model="verification" placeholder="Verification Code">
      </div>
      <br>
      <br>
      <div class="verifybutton">
        <button id="verify-button" type="button" name="verifybutton" @click="verifyUser">
          Verify
        </button>
      </div>
      <br>
      <div class="loginoptions">
        <div class="loginlink">
            <!--<router-link to="/login">Sign In</router-link>-->
            <!-- <a href="https://paxitesting.com/?loginaction=oauthclientlogin">
              Sign In
            </a> -->
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="resendConfirmationCode">
      <h4>Confirmation code expired? <br> Enter your email or phone and click resend code.</h4>
      <div class="resendFailure" v-if="isResendError == true">
        <br>
        <h4 >{{resendError}}</h4>
      </div>
      <br>
      <input class="inputbar" v-model="resendEmail">
      <div class="resendSuccess" v-if="isResendSuccess == true">
        <br>
        <h5>{{resendSuccess}}</h5>
      </div>
      <div class="resendCodeButton">
        <br>
        <button type="button" name="resendCodeButton" id="resend-code-button" @click="resendCode">
          Resend Code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  data() {
    return {
      email: this.$root.$data.email,
      resendEmail: this.$root.$data.resendEmail,
      authError: '',
      isAuthError: false,
      verifySuccess: false,
      verification: '',
      isResendError: false,
      isResendSuccess: false,
      resendError: '',
      resendSuccess: '',
    };
  },
  computed: {
    zoneInfo() {
      return new Date().toString().split(" ")[5];
    },
    updated_at() {
      return Date.now().toString();
    }
  },
  methods: {
    async verifyUser() {
      console.log("Attempting to verify user");
      try {
          await Auth.confirmSignUp(this.email, this.verification);
          this.verifySuccess = true;
          alert('User successfully verified. Closing tab...');
          window.close();
          //window.location = "https://paxitesting.com/?loginaction=oauthclientlogin";
      } catch (error) {
          this.isAuthError = true;
          this.authError = error.message;
      }
    },
    async resendCode() {
      console.log("Resending verification code to user's email or phone");
      try {
          await Auth.resendSignUp(this.resendEmail);
          this.isResendSuccess = true;
          this.isResendError = false;
          this.resendSuccess = "Code Sent."
      } catch (error) {
          this.isResendError = true;
          this.resendError = error.message;
      }
    },
  }
}
</script>

<style scoped>
  @import "../assets/styles/styles.css";
  .resendFailure,
  .passwordFailure {
    color: red;
  }
  .resendSuccess {
    color: green;
  }
  .passwordSuccess {
    color: green;
  }
  .entrybox {
    display: flex;
    justify-content: center;
  }
  .passwordEntry {
    align-items: center;
  }
  .inputbar-password {
    width: 372px;
    height: 40px;
    border:1px solid 	#BEBEBE;
    border-right: 0;
  }
  .input-visibility {
    color: 	#BEBEBE;
    padding: 5px;
    padding-top: 10px;
    border:1px solid #BEBEBE;
    border-left: 0;
  }
</style>
