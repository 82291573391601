import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

import '@aws-amplify/ui-components';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});


/*
 * Use this if using the full portal.paxi.com visualization
 *
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faEye, faEyeSlash)
Vue.component('font-awesome-icon', FontAwesomeIcon)
*/
//Vue.prototype.$domain = process.env.APP_DOMAIN

// Print the stage of the running system.
//console.log(process.env.VUE_APP_DOMAIN)

/*
 * Use this information if doing full authentication (rather than just verifying accounts). Maybe can move this to aws-exports.js
 *
Amplify.configure({
  Auth: {
    // (required)- Amazon Cognito Region
    region: <ENTER REGION>,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: <ENTER POOL ID>,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)

    userPoolWebClientId: '<ENTER CLIENT ID>',

    cookieStorage: {
      domain: process.env.VUE_APP_DOMAIN,
      path: '/',
      expires: 365,
      sameSite: "lax",
      secure: false
    },

    oauth: {
        domain: 'https://paxilogin.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://paxi.com',
        clientId: '<ENTER CLIENT ID>',
        responseType: 'code',
    },
  }
});
*/

Amplify.configure(awsconfig)

Vue.config.productionTip = false

new Vue({
  router,
  data : {
    email: '',
  },
  render: h => h(App)
}).$mount('#app')
